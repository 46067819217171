<template>
  <vx-card no-shadow>

    <vs-input 
    data-vv-validate-on="blur" 
    class="w-full" 
    v-validate="'required'" 
    label-placeholder="Old Password" 
    v-model="old_password"
    name="old_password" />
    <span class="text-danger text-sm">{{ errors.first('old_password') }}</span>

    <vs-input 
    data-vv-validate-on="blur" 
    class="w-full" 
    v-validate="'required|min:6'" 
    label-placeholder="New Password" 
    v-model="new_password" 
    type="password"
    name="new_password" />
    <span class="text-danger text-sm">{{ errors.first('new_password') }}</span>

    <vs-input 
    data-vv-validate-on="blur" 
    class="w-full" 
    v-validate="'required'" 
    label-placeholder="Confirm Password" 
    v-model="confirm_new_password"
    type="password"
    name="confirm_new_password" />
    <span class="text-danger text-sm">{{ errors.first('confirm_new_password') }}</span>

    <!-- Save & Reset Button -->
    <div class="flex flex-wrap items-center justify-end">
      <vs-button class="ml-auto mt-2" :disabled="!validateForm" @click="save">Simpan Perubahan</vs-button>
    </div>
  </vx-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data () {
    return {
      old_password: '',
      new_password: '',
      confirm_new_password: ''
    }
  },

  computed: {
    ...mapGetters({
      user : 'auth/user',
    }),
    validateForm () {
      return !this.errors.any() && this.old_password !== '' && this.new_password !== '' && this.confirm_new_password !== ''
    },
  },

  methods: {
    ...mapActions({
      setAuth   : 'auth/set',
    }),
    save () {
      if (!this.errors.any()) {
        if (this.new_password === this.confirm_new_password) {
          this.$vs.loading()
          let config = {
            headers: {
              'Authorization': 'Bearer ' + this.user.api_token,
            },
          }
          let formData = {
            'password' : this.old_password,
            'new_password' : this.new_password,
            'confirm_new_password' : this.confirm_new_password
          }
          this.axios.post('/passwordcek', formData, config)
          .then((response) => {
            let { data } = response
            this.$vs.notify({
              title: data.status,
              color: 'success',
            })
            this.axios.post('/passwordchange', formData, config)
            .then((response) => {
              let { data } = response
              this.$vs.loading.close()
              this.$vs.notify({
                title: data.status,
                buttonCancel: false,
                color: 'success',
                text: 'Pergantian password berhasil',
              })
              this.setAuth({})
              this.$router.push('/login')
            })
            .catch((error) => {
              let { data } = error.response
              this.$vs.loading.close()
              this.$vs.notify({
                title: data.error,
                text: 'Pergantian password gagal, coba beberapa saat lagi',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
              })
            })
          })
          .catch((error) => {
            this.$vs.loading.close()
            let { data } = error.response
            this.$vs.notify({
              title: 'Error',
              text: data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          })
        } else {
          this.$vs.notify({
            title: 'Error',
            text: 'Password baru anda tidak sama',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },

  }
}
</script>
